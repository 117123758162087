var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "order-requiring-attention-slide-component" } },
    [
      !_vm.loaded
        ? _c("ct-centered-spinner")
        : _vm.boiOra && !_vm.complete
        ? [
            _vm.boiOra
              ? _c("item-requiring-attention", {
                  attrs: { item: _vm.boiOra },
                  on: {
                    completed: function ($event) {
                      return _vm.completed()
                    },
                  },
                })
              : _c("no-order-item"),
          ]
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.boiOra,
              expression: "!boiOra",
            },
          ],
          staticClass: "mt-3 mb-0 button-container",
        },
        [
          _c(
            "div",
            { staticClass: "slide-viewer-button-row" },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: _vm.complete,
                    variant: "default",
                    "aria-label": "back button",
                  },
                  on: { click: _vm.back },
                },
                [
                  _c("fa-icon", {
                    staticClass: "fa-xs",
                    attrs: { icon: "chevron-left" },
                  }),
                  _vm._v(" Previous\n      "),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "next-button",
                  attrs: {
                    disabled: !_vm.complete && !_vm.progressBarComplete,
                    variant: "primary",
                    "aria-label": "continue button",
                  },
                  on: { click: _vm.next },
                },
                [
                  _c("span", { staticClass: "px-1" }, [
                    _vm._v("\n          Continue\n        "),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }