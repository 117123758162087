<template>
  <div id="order-requiring-attention-slide-component">
    <ct-centered-spinner v-if="!loaded" />

    <template v-else-if="boiOra && !complete">
      <item-requiring-attention
        v-if="boiOra"
        :item="boiOra"
        @completed="completed()"
      />
      <no-order-item v-else />
    </template>

    <div v-show="!boiOra" class="mt-3 mb-0 button-container">
      <div class="slide-viewer-button-row">
        <b-button
          :disabled="complete"
          variant="default"
          aria-label="back button"
          @click="back"
        >
          <fa-icon icon="chevron-left" class="fa-xs" />&nbsp;Previous
        </b-button>
        <b-button
          :disabled="!complete && !progressBarComplete"
          class="next-button"
          variant="primary"
          aria-label="continue button"
          @click="next"
        >
          <span class="px-1">
            Continue
          </span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { tagSession } from '@/common/modules/inspectlet'

export default {
  name: 'OrderRequiringAttentionSlide',
  components: {
    CtCenteredSpinner:      () => import('@/components/shared/CtCenteredSpinner'),
    NoOrderItem:            () => import('@/components/StagelineV2/slides/pendingFiling/NoOrderItem'),
    ItemRequiringAttention: () => import('@/components/ItemRequiringAttention.vue'),
  },
  props: {
    slide: null,
  },
  data() {
    return {
      loaded: false,
      complete: false,
      progressBarComplete: false,
    }
  },
  computed: {
    ...mapGetters('orderItems', [
      'orderItemsRequiringAttention',
    ]),
    ...mapGetters('stageline', [
      'company',
      'currentPeriod',
      'currentSlideIsLastOfCurrentPeriod',
    ]),
    boiOra() {
      //  TODO [STAG-2704] - Update to use slide -> layout sub type to pull ORA
      return this.orderItemsRequiringAttention.find(oi => oi.status === 'awaiting-client-input' && oi?.product?.filing_name === 'beneficial ownership information report')
    },
  },
  async mounted() {
    tagSession({
      stagelineV2: 'orderItemRequiringAttention',
      stagelinePeriod: this.currentPeriod.name,
      entity_type: this.company?.entity_type,
      companyId: this.company?.id,
    })

    this.loaded = false
    await this.loadOrderItemsRequiringAttention() // TODO [STAG-2704] - Should be able to just load ora for specific filing and company instead of all of them
    this.loaded = true
  },
  methods: {
    ...mapActions('dashpanel', [
      'refreshCompaniesRequiringCtaIds',
    ]),
    ...mapActions('orderItems', [
      'loadOrderItemsRequiringAttention',
    ]),
    ...mapActions('stageline', [
      'goToPreviousSlide',
      'setSlideLoaded',
    ]),
    back() {
      this.setSlideLoaded(false)
      this.goToPreviousSlide()
    },
    completed() {
      this.loaded = false

      // todo remove for other use cases in future
      this.refreshCompaniesRequiringCtaIds()

      this.$emit('next-slide')
      this.complete = true
    },
    async next() {
      this.loaded = false
      this.$emit('next-slide')
    },
  },
}
</script>

<style lang="scss" scoped>
#order-requiring-attention-slide-component {
  width: 100%;

  .loading-slide {
    text-align: center;

    h3 {
      margin-bottom: 2em;
    }

    .loading-bar {
      margin: 0 35% 2em;

      ::v-deep .progress-bar {
        background-color: #7D64D8;
      }
    }
  }
  .button-container {
    float: right;
  }
}
</style>
